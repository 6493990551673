import React from "react";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Logos from "./components/Logos";
import Footer from "./components/Footer";
import Galery from "./components/Galery";
import Referanslar from "./components/Referanslar";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
        <script src="/js/script.js" type="text/javascript" />
      </Helmet>
      <Header />
      <Home />
      <About />
      <Services />
      <Galery />
      <Referanslar />
      <Contact />
      <Logos />
      <Footer />
    </div>
  );
}

export default App;
