import React from "react";
import videoSource from "./video/project-5.mp4";
import videoSource01 from "./video/project-6.mp4";

const Galery = () => {
  return (
    <section class="projects" id="projects">
      <h1 class="heading"> Galeri </h1>

      <div class="box-container">
        <a href="images/project-1.jpg" class="box">
          <div class="image">
            <img src="images/project-1.jpg" alt="" />
          </div>

          <div class="content">
            <div class="info">
              <h3>BozKaya Vinç</h3>
              <p>Kiralama, Taşıma</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="images/project-2.jpg" class="box">
          <div class="image">
            <img src="images/project-2.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>BozKaya Vinç</h3>
              <p>Kiralama, Taşıma</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="images/project-3.jpg" class="box">
          <div class="image">
            <img src="images/project-3.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>BozKaya Vinç</h3>
              <p>Kiralama, Taşıma</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="images/project-4.jpg" class="box">
          <div class="image">
            <img src="images/project-4.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>BozKaya Vinç</h3>
              <p>Kiralama, Taşıma</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="images/project-5.mp4" class="box">
          <div class="image">
            <video muted>
              <source src={videoSource01} type="video/mp4" />
            </video>
          </div>
          <div class="content">
            <div class="info">
              <h3>BozKaya Vinç</h3>
              <p>Kiralama, Taşıma</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="images/project-6.mp4" class="box">
          <div class="image">
            <video muted>
              <source src={videoSource} type="video/mp4" />
            </video>
          </div>
          <div class="content">
            <div class="info">
              <h3>BozKaya Vinç</h3>
              <p>Kiralama, Taşıma</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>
      </div>
    </section>
  );
};

export default Galery;
