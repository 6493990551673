import React from 'react';

const Logos = () => {
  return (
    <section class="logo-container">

          <div class="swiper-slide slide"><img src="images/client-logo-1.png" alt="" /></div>

    </section>

  )
}

export default Logos;