import React from 'react';


const Referanslar = () => {

 
  return (
    <section class="refer" id="refer">

    <h1 class="heading"> Referanslar </h1>
 
    <div class="box-container">

        <div class="image">
            <img class="image_ref" src="images/project-1.png" alt="" />
        </div>
        <div class="image">
            <img class="image_ref" src="images/project-2.png" alt="" />
        </div>
        <div class="image">
            <img class="image_ref" src="images/project-3.png" alt="" />
        </div>
        <div class="image">
            <img class="image_ref" src="images/project-4.png" alt="" />
        </div>
    </div>
    </section>
  );
};

export default Referanslar;