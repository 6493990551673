import React from 'react';

const Home = () => {
  return (
<section className="home" id="home">
  <div className="swiper home-slider">
    <div className="swiper-wrapper">
      <section className="swiper-slide slide" style={{background: 'url(images/home-slide-1.jpg) no-repeat'}}>
        <div className="content">
          <h3>EN İYİ HİZMETİ VAAT EDİYORUZ </h3>
          <p>İhtiyaçlarınıza özel iş makinası kiralama. Profesyonel ekipmanlar, güvenilir hizmet. Projelerinizi güçlendirecek iş makinaları.</p>
          <a href="#about" className="btn">Hadi Başlayalım</a>
        </div>
      </section>
    </div>
  </div>
</section>


  )
}

export default Home;