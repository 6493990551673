import React from 'react';
import videoSource from "./video/about-vid.mp4";

const About = () => {
  return (
<section class="about" id="about">

<h1 class="heading"> Hakkımızda </h1>

<div class="row">

   <div class="video">
   <video autoPlay="autoplay" loop="loop" muted>
        <source src={videoSource} type="video/mp4" />
</video>
   </div>

   <div class="content">
      <h3>SİZİN İÇİN EN İYİSİNİ SAĞLAYACAĞIZ!</h3>
      <p>Müşteri memnuniyetini odak noktamız olarak benimsiyoruz. İşletmenizin ihtiyaçlarını anlamak ve doğru çözümleri sunmak için sizinle yakın bir işbirliği içinde çalışıyoruz. Projelerinizin gereksinimlerine uygun iş makinalarını titizlikle seçiyor ve güncel ekipmanlarımızı sürekli olarak bakım ve kontrol süreçlerinden geçiriyoruz. Bu sayede, en iyi performansı ve güvenilirliği sağlamaktan gurur duyuyoruz.</p>
      <p>Ekibimizde uzman teknisyenler ve deneyimli operatörler bulunmaktadır. Profesyonelliklerini ve teknik bilgilerini sürekli olarak güncelleyen ekip üyelerimiz, iş makinelerinin verimli bir şekilde kullanılmasını sağlar ve müşterilerimize en iyi sonuçları elde etmelerinde yardımcı olurlar. Ayrıca, müşterilerimize gerektiğinde teknik destek sağlamaktan ve sorularını yanıtlamaktan mutluluk duyarız.</p>
      <a href="#services" class="btn">Hizmetlerimiz</a>
   </div>

</div>

<div class="box-container">

   <div class="box">
      <h3>10+</h3>
      <p>yılı aşkın tecrübe</p>
   </div>

   <div class="box">
      <h3>1000+</h3>
      <p>proje tamamlandı</p>
   </div>

   <div class="box">
      <h3>790+</h3>
      <p>memnun müşteri</p>
   </div>

   <div class="box">
      <h3>Aktif</h3>
      <p>çalışanlarımızla hizmetinizdeyiz</p>
   </div>

</div>

</section>

  )
}

export default About;