
import React from 'react';

const Contact = () => {
  return (
    <section class="contact" id="contact">

    <h1 class="heading"> İletişim </h1>

    <div class="row">
        <iframe title="map" 
        class="map" 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d416.13263801912467!2d29.52246040876821!3d40.08071105398115!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ea74b05983a0f01%3A0x1e3afc1baa166ba3!2sBozKaya%20Vin%C3%A7!5e0!3m2!1str!2str!4v1686493228875!5m2!1str!2str" 
        allowfullscreen="" 
        loading="lazy">
        </iframe>
        <section>
            <div class="info">
                <i class="fas fa-phone"></i>
                <h3>Telefon Numarası</h3>
                <p>+90 544 402 95 66</p>
                <p>-</p>
            </div>
        
            <div class="info">
                <i class="fas fa-envelope"></i>
                <h3>E-Mail</h3>
                <p>bozkayavinc@gmail.com</p>
                <p></p>
            </div>
        
            <div class="info">
                <i class="fas fa-map-marker-alt"></i>
                <h3>Ofis Adresi</h3>
                <p>BozKaya Vinç, Orhaniye Mh. Gazi Magosa Cd. No:28, 16400 İnegöl/Bursa</p>
            </div>
        </section>
    </div>

    </section>

  )
}

export default Contact;