import React from "react";

const Services = () => {
  return (
    <section class="services" id="services">
      <h1 class="heading"> Hizmetler </h1>

      <div class="box-container">
        <div class="box">
          <img src="images/service-1.png" alt="" />
          <h3>Yapı İnşaatı</h3>
          <p>İnşaatınızı taşıyacak doğru makinayı kiralayın.</p>
        </div>

        <div class="box">
          <img src="images/service-2.png" alt="" />
          <h3>Tiny house Taşıma</h3>
          <p>Ahşap kulübe, prefabrik ev ve bungalov evlerinizi taşıyalım.</p>
        </div>

        <div class="box">
          <img src="images/service-3.png" alt="" />
          <h3>İnşaat Malzemesi Taşıma</h3>
          <p>İşlerinizi kolaylaştıran iş makineleri.</p>
        </div>

        <div class="box">
          <img src="images/service-4.png" alt="" />
          <h3>İşinizi Hızlandırın</h3>
          <p>Verimliliğinizi artırmak için güvenilir kiralama hizmeti.</p>
        </div>

        <div class="box">
          <img src="images/service-5.png" alt="" />
          <h3>İş Makinası Taşıma</h3>
          <p>Kaliteli ekipmanlar ile etkili çözümler sunuyoruz.</p>
        </div>

        <div class="box">
          <img src="images/service-6.png" alt="" />
          <h3>İhtiyaçlarınız İçin Buradayız</h3>
          <p>
            {" "}
            Söz verdiğimiz sürelerde işlerinizi teslim ediyor ve projelerinizin
            aksamadan ilerlemesini sağlıyoruz.
          </p>
        </div>

        <div class="box">
          <img src="images/service-1.png" alt="" />
          <h3>Vinç Kiralama Hizmeti</h3>
          <p>Saatlik, haftalık, aylık vinç kiralama.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
