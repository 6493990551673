import React from "react";

const Header = () => {
  return (
    <div>
      <header className="header">
        <title>İnegöl BozKaya Vinç</title>
        <meta name="robots" content="index, follow" />
        <meta name="copyright" content="BozKaya Vinç" />
        <meta
          name="abstract"
          content="İnegöl ilçesinde saatlik, haftalık, aylık vinç kiralama hizmeti sunulmaktadır."
        />
        <meta
          name="description"
          content="İnegöl ilçesinde saatlik, haftalık, aylık vinç, forklift, sepetli vinç, sepetsiz, manlift kiralama. Hızlı güvenilir hizmet. "
        />

        <a href="#home" className="logo">
          BozKaya <span>Vinç</span>
        </a>
        <nav className="navbar">
          <a href="#home">Ana Sayfa</a>
          <a href="#about">Hakkımızda</a>
          <a href="#services">Hizmetler</a>
          <a href="#projects">Galeri</a>
          <a href="#refer">Referanslar</a>
          <a href="#contact">İletişim</a>
        </nav>
        <div className="icons">
          <div id="menu-btn" className="fas fa-bars" />
          <div id="info-btn" className="fas fa-info-circle" />
        </div>
      </header>
      <div className="contact-info">
        <div id="close-contact-info" className="fas fa-times" />
        <div className="info">
          <i className="fas fa-phone" />
          <h3>Telefon Numarası</h3>
          <p>+90 544 402 95 66</p>
          <p>-</p>
        </div>
        <div className="info">
          <i className="fas fa-envelope" />
          <h3>E-Mail</h3>
          <p>bozkayavinc@gmail.com</p>
          <p>-</p>
        </div>
        <div className="info">
          <i className="fas fa-map-marker-alt" />
          <h3>Ofis Adresi</h3>
          <p>
            BozKaya Vinç, Orhaniye Mh. Gazi Magosa Cd. No:28, 16400 İnegöl/Bursa
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
