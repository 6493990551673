import React from "react";

const Footer = () => {
  return (
    <section class="footer">
      <div class="links">
        <a class="btn" href="#home">
          Ana Sayfa
        </a>
        <a class="btn" href="#about">
          Hakkımızda
        </a>
        <a class="btn" href="#services">
          Hizmetler
        </a>
        <a class="btn" href="#projects">
          Galeri
        </a>
        <a class="btn" href="#refer">
          Referanslar
        </a>
        <a class="btn" href="#contact">
          İletişim
        </a>
      </div>

      <div class="credit">
        {" "}
        BozKaya <span>Vinç</span> | © Copyright{" "}
      </div>
    </section>
  );
};

export default Footer;
